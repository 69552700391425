/*
 * This file is part of Solana Reference Stake Pool code.
 *
 * Copyright © 2023, mFactory GmbH
 *
 * Solana Reference Stake Pool is free software: you can redistribute it
 * and/or modify it under the terms of the GNU Affero General Public License
 * as published by the Free Software Foundation, either version 3
 * of the License, or (at your option) any later version.
 *
 * Solana Reference Stake Pool is distributed in the hope that it
 * will be useful, but WITHOUT ANY WARRANTY; without even the implied
 * warranty of MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.
 * See the GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.
 * If not, see <https://www.gnu.org/licenses/agpl-3.0.html>.
 *
 * You can be released from the requirements of the Affero GNU General Public License
 * by purchasing a commercial license. The purchase of such a license is
 * mandatory as soon as you develop commercial activities using the
 * Solana Reference Stake Pool code without disclosing the source code of
 * your own applications.
 *
 * The developer of this program can be contacted at <info@mfactory.ch>.
 */

import { defineStore } from 'pinia'
import { computed, ref, watch } from 'vue'
import { APY_VALIDATOR_ID, DEFAULT_APY } from '~/config'
import { useConnectionStore, useValidatorsStore } from '~/store'

export type Apy = {
  staking: number
  jito: number
}
export const useApyStore = defineStore('apy', () => {
  const validatorsStore = useValidatorsStore()
  const connectionStore = useConnectionStore()

  const selectedApy = ref()

  const validators = computed(() => validatorsStore.allValidators.filter(v => v.inJpool))
  const cluster = computed(() => connectionStore.cluster)

  if (APY_VALIDATOR_ID) {
    watch([validators], async ([validators]) => {
      const apyValidator = validators.find(v => v.id === APY_VALIDATOR_ID)
      if (apyValidator) {
        selectedApy.value = apyValidator.apy
      }
    })
  }

  const apy = computed<Apy>(() => {
    if (validators.value.length === 0 || cluster.value !== 'mainnet-beta') {
      return {
        staking: DEFAULT_APY,
        jito: 0,
      }
    }
    if (selectedApy.value) {
      return selectedApy.value
    }

    const sumApy = validators.value.reduce(
      (sum, v) => {
        return {
          stake: sum.stake + v.jpoolLamports,
          apy: sum.apy + v.apy * v.jpoolLamports,
          jitoApy: sum.jitoApy + v.jitoApy * v.jpoolLamports,
        }
      }, {
        apy: 0,
        jitoApy: 0,
        stake: 1, // 1 lamport does not give a noticeable calculation error, but prevents division by 0
      },
    )
    /**
     * stake-weighted APY - apy calculation taking into account how much stake is on each validator
     */
    return {
      staking: sumApy.apy / sumApy.stake / 100,
      jito: sumApy.jitoApy / sumApy.stake / 100,
    }
  })

  watch(apy, () => {
  }, { immediate: true })

  return {
    apy,
  }
})
